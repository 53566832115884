import axios from "axios";

export default {
    namespaced: true,
    state: {
        playerEntryHistoryData: {},
        loadingPlayerEntryHistoryData: false,
        loadingError: false,
        downloadingPdf: false,
    },
    getters: {
        getDownloadingPdf: (state) => state.downloadingPdf,
        getPlayerEntryHistoryData: (state) => state.playerEntryHistoryData,
        getLoadingPlayerEntryHistoryData: (state) => state.loadingPlayerEntryHistoryData,
        getLoadingError: (state) => state.loadingError
    },
    mutations: {
        setPlayerEntryHistoryData(state, data) {
            state.playerEntryHistoryData = data;
        },

        startLoadingPlayerEntryHistoryData(state) {
            state.loadingPlayerEntryHistoryData = true;
        },

        stopLoadingPlayerEntryHistoryData(state) {
            state.loadingPlayerEntryHistoryData = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startPlayerEntryHistoryPdf(state) {
            state.downloadingPdf = true;
        },

        stopPlayerEntryHistoryPdf(state) {
            state.downloadingPdf = false;
        },
    },

    actions: {
        async findPlayerEntryHistory(context, options = {}) {
            context.commit('startLoadingPlayerEntryHistoryData');
            context.commit('clearLoadingError');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/EntryHistory/Edition/${options.tournamentId}/${options.season}/Player/${options.playerId}`, {
                    params: {
                        pageSize: options.options.pageSize || 20,
                        sortBy: options.options.sortBy || '',
                        sortDir: options.options.sortDir === 'descending' ? -1 : 1,
                        page: options.options.page,
                    },
                });
                context.commit('setPlayerEntryHistoryData', result.data);
                context.commit('stopLoadingPlayerEntryHistoryData');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPlayerEntryHistoryData');
                return Promise.reject(error);
            }
        },
        async getPlayerEntryHistoryPdf(context, request = {}) {
            context.commit('startPlayerEntryHistoryPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/EntryHistory/Edition/${request.tournamentId}/${request.season}/Player/${request.playerId}/Pdf`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopPlayerEntryHistoryPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopPlayerEntryHistoryPdf');
            }
        },
        async getWithdrawalReasonPdf(context, request = {}) {
            context.commit('startPlayerEntryHistoryPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/EntryHistory/Edition/${request.tournamentId}/${request.season}/Player/${request.playerId}/WithdrawalReason/Pdf`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopPlayerEntryHistoryPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopPlayerEntryHistoryPdf');
            }
        }
    }
}