import { readonly } from 'vue';
import { isValid, Model } from './model';

export class PlayerEntryHistory extends Model {
    isValid() {
        return isValid(PlayerEntryHistory.schema, this);
    }
}

PlayerEntryHistory.schema = {
    fields: {
        playerNumber: { type: 'player', displayName: 'Player Id', readonly: true },
        playerFirstName: { type: 'string', displayName: 'First Name', readonly: true },
        playerLastName: { type: 'string', displayName: 'Last Name', readonly: true },
        entryDate: { type: 'us-date', displayName: 'Entry Date', readonly: true },
        entryType: { type: 'string', displayName: 'Entry Type', readonly: true },
        entryStatus: { type: 'string', displayName: 'Entry Status', readonly: true },
        acceptedDate: { type: 'us-date', displayName: 'Accepted Date', readonly: true },
        tournamentNumber: { type: 'number', displayName: 'Tournament Id', readonly: true },
        tournamentYear: { type: 'number', displayName: 'Tournament Year', readonly: true, sortable: true },
        tournamentName: { type: 'string', displayName: 'Tournament Name', readonly: true, sortable: true },
        withdrawalDate: { type: 'us-date', displayName: 'Withdrawal Date', readonly: true},
        withdrawalReason: { type: 'string', displayName: 'Withdrawal Reason', readonly: true},
    }
}